<template>
    <a-card>
        <a-page-header
            title="橙子建站模板管理"
        />

        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="模板名称">
                        <a-input
                            v-model="form.landingPageTemplateName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="模板ID">
                        <a-input
                            v-model="form.adLandingPageId"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="集客场景">
                        <a-select
                            mode="multiple"
                            v-model="form.customerSceneList"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in customerSceneList"
                                :key="item.code"
                            >
                                {{ item.desc }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="车系">
                        <a-select
                            mode="multiple"
                            v-model="form.carSeriesIdList"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="状态">
                        <a-select
                            v-model="form.landingPageStatus"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option key="1">有效</a-select-option>
                            <a-select-option key="2">停用</a-select-option>
                            <a-select-option key="3">删除</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="识别结果">
                        <a-select
                            v-model="form.recognitionStatus"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option key="1">正常</a-select-option>
                            <a-select-option key="2">异常</a-select-option>
                            <a-select-option key="3">人工修改</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="模板转化率">
                        <a-input-number v-model="form.templateConvertRateMin" :min="0" />
                        ~
                        <a-input-number v-model="form.templateConvertRateMax" :min="0" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
            <a-row>
                <a-col>
                    <a-space>
                        <a-button type="primary" @click="onBatch('abled')">批量有效</a-button>
                        <a-button type="primary" @click="onBatch('disabled')">批量停用</a-button>
                        <a-button type="primary" @click="onBatch('gift')">批量赠送广告主</a-button>
                        <a-button type="primary" @click="onBatch('latest')">获取最新模板</a-button>
                        <div>最新更新时间：{{ updateTime || '-' }}</div>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :row-selection="rowSelection"
            :pagination='false'
            :scroll="{x: 1500}"
            class="mt-20"
            @change="handleTableChange"
        >
            <div
                slot="cover"
                slot-scope="text, record"
            >
                <span v-if="record.thumbnail">
                    <img :src="record.thumbnail" class="cover" v-if="record.landingPageStatus == 3">
                    <img :src="record.thumbnail" class="cover" v-else @click="handlePreviewOpen(record.thumbnail, 'img')">
                </span>
                <span v-else>-</span>
            </div>
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <a @click="jumpDetailPage(record.id)" v-if="record.landingPageStatus == 2">修改</a>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />

        <div class="mt-20">
            <div>PS:</div>
            <div>1、同步率数据截止昨日</div>
            <div>2、模板累计访问量和模板转化率统计数据截止2天前，例如：今天是2023年1月3日，系统统计数据截止2023年1月2日</div>
            <div>3、每天凌晨自动获取增量模版和更新模版名称</div>
            <div>4、每天凌晨自动赠送状态为有效的模板给品牌匹配的广告主</div>
        </div>

        <preview-modal
            :visible="previewVisible"
            :preview-src="previewSrc"
            width="50%"
            @cancel="handlePreviewCancel"
        />
    </a-card>
</template>

<script>
import utils from "@/utils/FormatUtil.js";
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";

export default {
    components: {
        previewModal
    },
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {},
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '状态',
                    dataIndex: 'landingPageStatus',
                    customRender: (text, row) => {
                        let txt = <div class="red">停用</div>
                        return row.landingPageStatus == 1 ? '有效' : row.landingPageStatus == 2 ? txt : row.landingPageStatus == 3 ? '删除' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '同步率',
                    dataIndex: 'syncRate',
                    sorter: true,
                    customRender: (text, row) => {
                        return (row.syncRate ?? '') !== '' ? `${(row.syncRate).toFixed(2)}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '模板缩略图',
                    scopedSlots: { customRender: 'cover' },
                },
                {
                    align: 'center',
                    title: '模板名称',
                    dataIndex: 'name',
                    customRender:(text, row) => {
                        let txt1 = <div style="text-align: left;">
                                <div><a on-click={() => this.handlePreviewOpen(row.previewUrl, 'url')}>{ row.name }</a></div>
                                <div class="txt">模板ID: {row.adLandingPageId}</div>
                            </div>
                        let txt2 = <div style="text-align: left;">
                                <div>{ row.name }</div>
                                <div class="txt">模板ID: {row.adLandingPageId}</div>
                            </div>
                        return row.landingPageStatus == 3 ? txt2 : txt1
                    }
                },
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系',
                    dataIndex: 'carSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '集客场景',
                    dataIndex: 'customerSceneDesc',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '模板适用范围',
                    customRender: (text, row) => {
                        return row.templateScope == 1 ? '车系' : row.templateScope == 2 ? '品牌' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系、集客场景识别结果',
                    dataIndex: 'recognitionStatus',
                    customRender: (text, row) => {
                        return row.recognitionStatus == 1 ? '正常' : row.recognitionStatus == 2 ? '异常' : row.recognitionStatus == 3 ? '人工修复' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '模板累计访问量',
                    sorter: true,
                    dataIndex: 'viewCount',
                    customRender: (text, row) => {
                        return (row.viewCount ?? '') !== '' ? utils.humanReadable(row.viewCount, 2) : '-'
                    }
                },
                {
                    align: 'center',
                    title: '模板转化率',
                    sorter: true,
                    dataIndex: 'templateConvertRate',
                    customRender: (text, row) => {
                        return (row.templateConvertRate ?? '') !== '' ? `${(row.templateConvertRate).toFixed(2)}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            selectedRowKeys: [],
            principalList: [],
            seriesList: [],
            customerSceneList: [],
            updateTime: '',
            previewVisible: false,
            previewSrc: '',
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys
                },
                getCheckboxProps: record => ({
                    props: {
                        disabled: record.landingPageStatus == 3,
                        // state: record.state
                    },
                })
            };
        },
    },
    created() {
        this.getPrincipalList()
        this.getPrincipalSeriesList()
        this.getCustomerScene()
        this.getDataList()
        this.getLastUpdateTime()
    },
    methods: {
        handlePreviewOpen(url, type) {
            if(type == 'url') {
                window.open(url)
            } else {
                this.previewVisible = true
                this.previewSrc = url
            }
        },
        handlePreviewCancel() {
            this.previewVisible = false
            this.previewSrc = ''
        },
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangePrincipal(id) {
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handleTableChange(pagination, filters, sorter) {
            this.getDataList({
                sortList: [{
                    fieldName: sorter.columnKey,
                    orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
                }]
            });
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList(data = {}) {
            this.loading = true
            let params = {
                ...this.form,
                ...data,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.materialManage.getLandingPageTemplateList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        onBatch(type) {
            if(this.selectedRowKeys.length < 1 && type !== 'latest') {
                this.$message.error('请先勾选模板')
                return
            }
            let request = ''
            switch(type) {
                case 'abled': //有效
                    request = 'batchValidByIds'
                    break;
                case 'disabled': //停用
                    request = 'batchDisableByIds'
                    break;
                case 'gift': //赠送
                    request = 'batchGiftByIds'
                    break;
                case 'latest': //最新
                    request = 'batchLatestByIds'
                    break;
            }

            this.$api.core.materialManage[request](this.selectedRowKeys).then((res) => {
                if(res.code == 200) {
                    if(type == 'latest') {
                        this.updateTime = res.data.updateTime
                        this.$message.success('完成操作，1分钟后请刷新页面查看”')
                    } else {
                        this.getDataList()
                        this.selectedRowKeys = []
                        this.$message.success('操作成功')
                    }
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        jumpDetailPage(id) {
            this.$router.push({
                path: `/websiteTemplateManage/detail`,
                query: {
                    id,
                }
            })
        },
        getLastUpdateTime() {
            this.$api.core.materialManage.getLastUpdateTime().then((res) => {
                if(res.code == 200) {
                    this.updateTime = res.data.updateTime
                } else {
                    this.$message.error('获取更新模板的时间失败')
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.mt-20 {
    margin-top: 20px;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.cover {
    width: 60px;
}
.txt {
    color: #ccc;
}
</style>
